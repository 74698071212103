<template>
    <svg width="75" height="73" viewBox="0 0 75 73" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6708_109135)">
            <path d="M72.3656 24.0816C79.3036 40.2694 72.0696 59.1323 56.2082 66.2129C40.3467 73.2936 21.8641 65.9109 14.9262 49.7231C7.98825 33.5353 15.2222 14.6724 31.0836 7.59174C46.9451 0.511047 65.4277 7.89382 72.3656 24.0816Z" fill="url(#paint0_linear_6708_109135)" />
            <path d="M31.1679 27.5489C32.2355 30.0392 31.1228 32.9413 28.6827 34.0308C26.2425 35.1204 23.399 33.9848 22.3314 31.4945C21.2638 29.0042 22.3764 26.1021 24.8166 25.0126C27.2567 23.923 30.1003 25.0586 31.1679 27.5489Z" fill="url(#paint1_linear_6708_109135)" />
            <path d="M60.6207 14.5181C61.2337 15.2437 61.1179 16.1926 60.3621 16.6375C59.6064 17.0824 58.4968 16.8548 57.8838 16.1291C57.2708 15.4035 57.3866 14.4546 58.1424 14.0097C58.8982 13.5648 60.0077 13.7924 60.6207 14.5181Z" fill="url(#paint2_linear_6708_109135)" />
            <path d="M71.6956 37.5107C72.6497 37.8498 73.0506 39.2167 72.591 40.5639C72.1314 41.9111 70.9853 42.7284 70.0312 42.3893C69.077 42.0503 68.6761 40.6833 69.1357 39.3361C69.5954 37.989 70.7414 37.1717 71.6956 37.5107Z" fill="url(#paint3_linear_6708_109135)" />
            <path d="M42.7287 6.06676C42.7903 6.61597 41.7145 7.19266 40.3258 7.35482C38.937 7.51699 37.7614 7.20323 37.6998 6.65402C37.6382 6.10481 38.7141 5.52813 40.1028 5.36596C41.4915 5.20379 42.6672 5.51755 42.7287 6.06676Z" fill="url(#paint4_linear_6708_109135)" />
            <path d="M54.4959 55.2826C55.1695 58.8619 52.3423 62.4247 48.1812 63.2403C44.0202 64.0558 40.1009 61.8154 39.4273 58.2361C38.7538 54.6568 41.581 51.094 45.742 50.2784C49.9031 49.4628 53.8223 51.7033 54.4959 55.2826Z" fill="url(#paint5_linear_6708_109135)" />
            <path d="M33.0677 6.31063C33.2768 6.74742 32.0302 7.80764 30.2833 8.67868C28.5364 9.54972 26.9508 9.90175 26.7416 9.46495C26.5325 9.02815 27.7792 7.96794 29.5261 7.0969C31.273 6.22585 32.8586 5.87383 33.0677 6.31063Z" fill="#111111" />
            <path d="M33.3428 6.885C33.7036 7.63874 32.58 8.95588 30.833 9.82692C29.0861 10.698 27.3775 10.7931 27.0166 10.0393C26.6558 9.28559 27.7794 7.96845 29.5263 7.09741C31.2733 6.22636 32.9819 6.13127 33.3428 6.885Z" fill="#6F9EFF" />
            <path d="M32.0681 6.4009C32.129 6.52799 31.1865 7.12555 29.963 7.7356C28.7395 8.34564 27.6984 8.73715 27.6376 8.61006C27.5767 8.48297 28.5192 7.8854 29.7427 7.27536C30.9661 6.66531 32.0073 6.2738 32.0681 6.4009Z" fill="#BCD2FF" />
            <path d="M12.1868 41.3153C13.4665 40.9177 15.251 43.0995 16.1727 46.1886C17.0944 49.2776 16.8042 52.1042 15.5245 52.5019C14.2448 52.8996 12.4603 50.7178 11.5386 47.6287C10.617 44.5396 10.9072 41.713 12.1868 41.3153Z" fill="#538BFF" />
            <path d="M37.6526 67.7809C37.6816 67.3619 40.2683 67.0742 43.4301 67.1383C46.5919 67.2024 49.1315 67.594 49.1025 68.013C49.0735 68.432 46.4868 68.7197 43.325 68.6556C40.1632 68.5915 37.6236 68.1999 37.6526 67.7809Z" fill="url(#paint6_linear_6708_109135)" />
            <path d="M37.6902 67.2305C37.7403 66.5075 40.344 65.9733 43.5058 66.0374C46.6676 66.1015 49.1902 66.7396 49.1401 67.4626C49.09 68.1856 46.4862 68.7198 43.3244 68.6557C40.1626 68.5916 37.6401 67.9535 37.6902 67.2305Z" fill="url(#paint7_linear_6708_109135)" />
            <path d="M39.1543 68.1281C39.1628 68.0062 40.9648 67.9438 43.1792 67.9887C45.3936 68.0336 47.1819 68.1688 47.1734 68.2907C47.165 68.4126 45.363 68.475 43.1486 68.4301C40.9342 68.3853 39.1459 68.25 39.1543 68.1281Z" fill="#150303" />
            <path d="M70.223 54.8526C69.6067 54.5662 70.0357 52.2529 71.1813 49.6857C72.3268 47.1186 73.755 45.2697 74.3713 45.5561C74.9876 45.8425 74.5585 48.1558 73.413 50.723C72.2675 53.2901 70.8393 55.139 70.223 54.8526Z" fill="url(#paint8_linear_6708_109135)" />
            <path d="M69.4142 54.4765C68.3508 53.9822 68.4173 51.5005 69.5628 48.9333C70.7083 46.3661 72.4991 44.6857 73.5625 45.18C74.626 45.6743 74.5594 48.156 73.4139 50.7232C72.2684 53.2903 70.4777 54.9707 69.4142 54.4765Z" fill="url(#paint9_linear_6708_109135)" />
            <path d="M71.2393 53.8314C71.06 53.7481 71.565 52.223 72.3673 50.425C73.1696 48.6271 73.9654 47.2371 74.1447 47.3205C74.324 47.4038 73.819 48.9289 73.0167 50.7268C72.2144 52.5248 71.4186 53.9148 71.2393 53.8314Z" fill="#9CBCFF" />
            <path d="M69.9328 19.5424C69.3312 19.9609 68.0572 19.123 67.0871 17.6708C66.1171 16.2186 65.8184 14.7021 66.42 14.2835C67.0216 13.865 68.2956 14.7029 69.2656 16.1551C70.2357 17.6073 70.5344 19.1238 69.9328 19.5424Z" fill="#538BFF" />
            <path d="M70.185 18.3409C70.0836 18.4115 69.4506 17.6442 68.7712 16.6272C68.0918 15.6101 67.6233 14.7284 67.7247 14.6578C67.8262 14.5873 68.4592 15.3545 69.1385 16.3716C69.8179 17.3887 70.2864 18.2704 70.185 18.3409Z" fill="#6F9EFF" />
            <path d="M50.8622 32.5976C50.8622 34.2965 49.5128 35.6738 47.8481 35.6738C46.1835 35.6738 44.834 34.2965 44.834 32.5976C44.834 30.8987 46.1835 29.5215 47.8481 29.5215C49.5128 29.5215 50.8622 30.8987 50.8622 32.5976Z" fill="#538BFF" />
            <path d="M24.82 55.0747C24.0633 55.7401 22.5691 55.236 21.4826 53.9488C20.396 52.6617 20.1287 51.0789 20.8854 50.4136C21.6421 49.7483 23.1363 50.2524 24.2229 51.5395C25.3094 52.8266 25.5767 54.4094 24.82 55.0747Z" fill="#6F9EFF" />
            <path d="M48.4501 11.6806C48.4501 12.7 47.1007 13.5263 45.436 13.5263C43.7713 13.5263 42.4219 12.7 42.4219 11.6806C42.4219 10.6613 43.7713 9.83496 45.436 9.83496C47.1007 9.83496 48.4501 10.6613 48.4501 11.6806Z" fill="#1C4EB5" />
            <path d="M66.2376 50.5997C66.8943 51.3678 66.4098 52.8959 65.1555 54.0129C63.9011 55.1298 62.3519 55.4126 61.6953 54.6445C61.0386 53.8765 61.5231 52.3484 62.7775 51.2314C64.0318 50.1145 65.581 49.8317 66.2376 50.5997Z" fill="#286EFF" />
        </g>
        <defs>
            <filter id="filter0_d_6708_109135" x="0" y="0.902344" width="75" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-7" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6708_109135" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6708_109135" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_6708_109135" x1="31.0837" y1="7.59179" x2="57.0791" y2="65.8242" gradientUnits="userSpaceOnUse">
                <stop stop-color="#286EFF" />
                <stop offset="1" stop-color="#93C4FF" />
            </linearGradient>
            <linearGradient id="paint1_linear_6708_109135" x1="24.8166" y1="25.0125" x2="28.8166" y2="33.971" gradientUnits="userSpaceOnUse">
                <stop stop-color="#286EFF" />
                <stop offset="1" stop-color="#286EFF" />
            </linearGradient>
            <linearGradient id="paint2_linear_6708_109135" x1="58.1424" y1="14.0097" x2="59.8624" y2="16.9316" gradientUnits="userSpaceOnUse">
                <stop stop-color="#286EFF" />
                <stop offset="1" stop-color="#286EFF" />
            </linearGradient>
            <linearGradient id="paint3_linear_6708_109135" x1="69.1357" y1="39.3361" x2="72.606" y2="40.5201" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BCD2FF" />
                <stop offset="1" stop-color="#BCD2FF" />
            </linearGradient>
            <linearGradient id="paint4_linear_6708_109135" x1="40.1028" y1="5.3659" x2="40.3349" y2="7.3537" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BCD2FF" />
                <stop offset="1" stop-color="#BCD2FF" />
            </linearGradient>
            <linearGradient id="paint5_linear_6708_109135" x1="45.742" y1="50.2784" x2="48.2789" y2="63.2211" gradientUnits="userSpaceOnUse">
                <stop stop-color="#286EFF" />
                <stop offset="1" stop-color="#286EFF" />
            </linearGradient>
            <linearGradient id="paint6_linear_6708_109135" x1="49.6565" y1="67.7671" x2="37.6298" y2="68.3656" gradientUnits="userSpaceOnUse">
                <stop stop-color="#286EFF" />
                <stop offset="1" stop-color="#286EFF" />
            </linearGradient>
            <linearGradient id="paint7_linear_6708_109135" x1="49.707" y1="67.0304" x2="37.6383" y2="67.0276" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B1CBFF" />
                <stop offset="1" stop-color="#286EFF" />
            </linearGradient>
            <linearGradient id="paint8_linear_6708_109135" x1="70.1525" y1="54.7458" x2="74.5152" y2="45.5855" gradientUnits="userSpaceOnUse">
                <stop stop-color="#38BCD2" />
                <stop offset="1" stop-color="#BCD2FF" />
            </linearGradient>
            <linearGradient id="paint9_linear_6708_109135" x1="69.2727" y1="54.3367" x2="73.5996" y2="45.1584" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BCD2FF" />
                <stop offset="1" stop-color="#BCD2FF" />
            </linearGradient>
        </defs>
    </svg>
</template>
