import validate from "/home/galaxyturbo/public_html/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/home/galaxyturbo/public_html/middleware/redirectTrailingSlash.global.ts";
import manifest_45route_45rule from "/home/galaxyturbo/public_html/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/galaxyturbo/public_html/middleware/admin.ts"),
  "check-slug": () => import("/home/galaxyturbo/public_html/middleware/checkSlug.ts"),
  "member-logged-in": () => import("/home/galaxyturbo/public_html/middleware/memberLoggedIn.ts")
}