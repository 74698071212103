import { default as _91slug_93ixjhIeu0wIMeta } from "/home/galaxyturbo/public_html/pages/[slug].vue?macro=true";
import { default as _91slug_93HNyPwB0D8JMeta } from "/home/galaxyturbo/public_html/pages/a/[slug].vue?macro=true";
import { default as indexK5XZHKajSAMeta } from "/home/galaxyturbo/public_html/pages/a/index.vue?macro=true";
import { default as aboutKCrnZQmFGuMeta } from "/home/galaxyturbo/public_html/pages/about.vue?macro=true";
import { default as indexW7DkG05hpeMeta } from "/home/galaxyturbo/public_html/pages/account/downloads/index.vue?macro=true";
import { default as editYkosiP1tOXMeta } from "/home/galaxyturbo/public_html/pages/account/edit.vue?macro=true";
import { default as index9HHOV4mlswMeta } from "/home/galaxyturbo/public_html/pages/account/index.vue?macro=true";
import { default as indexNSTnaDHnvpMeta } from "/home/galaxyturbo/public_html/pages/account/installments/index.vue?macro=true";
import { default as _91id_93roeKjF3Ns8Meta } from "/home/galaxyturbo/public_html/pages/account/orders/[id].vue?macro=true";
import { default as indexEAIGk9DksDMeta } from "/home/galaxyturbo/public_html/pages/account/orders/index.vue?macro=true";
import { default as verificationuCEOoI5MdDMeta } from "/home/galaxyturbo/public_html/pages/account/orders/verification.vue?macro=true";
import { default as accountQ9jEXhBUhxMeta } from "/home/galaxyturbo/public_html/pages/account.vue?macro=true";
import { default as car_45paintuGj103iWkaMeta } from "/home/galaxyturbo/public_html/pages/car-paint.vue?macro=true";
import { default as _91_46_46_46slug_93LlGWgBbKbiMeta } from "/home/galaxyturbo/public_html/pages/cat/[...slug].vue?macro=true";
import { default as checkoutGql7hEVcZjMeta } from "/home/galaxyturbo/public_html/pages/checkout.vue?macro=true";
import { default as contactLmOwi7447EMeta } from "/home/galaxyturbo/public_html/pages/contact.vue?macro=true";
import { default as _91slug_93IyDaQqJmdbMeta } from "/home/galaxyturbo/public_html/pages/course/[slug].vue?macro=true";
import { default as indexKJ80wnajD1Meta } from "/home/galaxyturbo/public_html/pages/dashboard/comments/index.vue?macro=true";
import { default as indexuQz1962htQMeta } from "/home/galaxyturbo/public_html/pages/dashboard/consult/index.vue?macro=true";
import { default as indexf9erX84mz4Meta } from "/home/galaxyturbo/public_html/pages/dashboard/index.vue?macro=true";
import { default as _91id_93I0kpFvhp77Meta } from "/home/galaxyturbo/public_html/pages/dashboard/pages/[id].vue?macro=true";
import { default as indexbiUy4WbPW7Meta } from "/home/galaxyturbo/public_html/pages/dashboard/pages/index.vue?macro=true";
import { default as _91slug_93DObDGhspbtMeta } from "/home/galaxyturbo/public_html/pages/dashboard/posts/[slug].vue?macro=true";
import { default as category04D4PDdrOQMeta } from "/home/galaxyturbo/public_html/pages/dashboard/posts/category.vue?macro=true";
import { default as indexR21ieddf3sMeta } from "/home/galaxyturbo/public_html/pages/dashboard/posts/index.vue?macro=true";
import { default as new_46clienteKsrd4GRlHMeta } from "/home/galaxyturbo/public_html/pages/dashboard/posts/new.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/galaxyturbo/public_html/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexRpzrVJV7N1Meta } from "/home/galaxyturbo/public_html/pages/dashboard/settings/index.vue?macro=true";
import { default as _91id_93ZJmNlPcgHuMeta } from "/home/galaxyturbo/public_html/pages/dashboard/users/[id].vue?macro=true";
import { default as indexvRnrvOcpuJMeta } from "/home/galaxyturbo/public_html/pages/dashboard/users/index.vue?macro=true";
import { default as _91id_93iI8w9aheyyMeta } from "/home/galaxyturbo/public_html/pages/dashboard/uservideos/[id].vue?macro=true";
import { default as indexJhGcxuQpKqMeta } from "/home/galaxyturbo/public_html/pages/dashboard/uservideos/index.vue?macro=true";
import { default as dashboardX09rYYyG9uMeta } from "/home/galaxyturbo/public_html/pages/dashboard.vue?macro=true";
import { default as indexp2d7NN5f3CMeta } from "/home/galaxyturbo/public_html/pages/index.vue?macro=true";
import { default as landina3WRaIq7TqMeta } from "/home/galaxyturbo/public_html/pages/landin.vue?macro=true";
import { default as indexEWmEL52y2nMeta } from "/home/galaxyturbo/public_html/pages/notadminpanel/index.vue?macro=true";
import { default as pdr_45planishingQ5yvyk0JO4Meta } from "/home/galaxyturbo/public_html/pages/pdr-planishing.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/galaxyturbo/public_html/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "a-slug",
    path: "/a/:slug()",
    component: () => import("/home/galaxyturbo/public_html/pages/a/[slug].vue").then(m => m.default || m)
  },
  {
    name: "a",
    path: "/a",
    component: () => import("/home/galaxyturbo/public_html/pages/a/index.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/galaxyturbo/public_html/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accountQ9jEXhBUhxMeta?.name,
    path: "/account",
    meta: accountQ9jEXhBUhxMeta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-downloads",
    path: "downloads",
    component: () => import("/home/galaxyturbo/public_html/pages/account/downloads/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit",
    path: "edit",
    component: () => import("/home/galaxyturbo/public_html/pages/account/edit.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/home/galaxyturbo/public_html/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-installments",
    path: "installments",
    component: () => import("/home/galaxyturbo/public_html/pages/account/installments/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id",
    path: "orders/:id()",
    component: () => import("/home/galaxyturbo/public_html/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/home/galaxyturbo/public_html/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-verification",
    path: "orders/verification",
    component: () => import("/home/galaxyturbo/public_html/pages/account/orders/verification.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "car-paint",
    path: "/car-paint",
    meta: car_45paintuGj103iWkaMeta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/car-paint.vue").then(m => m.default || m)
  },
  {
    name: "cat-slug",
    path: "/cat/:slug(.*)*",
    meta: _91_46_46_46slug_93LlGWgBbKbiMeta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/cat/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/home/galaxyturbo/public_html/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/galaxyturbo/public_html/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "course-slug",
    path: "/course/:slug()",
    component: () => import("/home/galaxyturbo/public_html/pages/course/[slug].vue").then(m => m.default || m)
  },
  {
    name: dashboardX09rYYyG9uMeta?.name,
    path: "/dashboard",
    meta: dashboardX09rYYyG9uMeta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-comments",
    path: "comments",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/comments/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-consult",
    path: "consult",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/consult/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    meta: indexf9erX84mz4Meta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages-id",
    path: "pages/:id()",
    meta: _91id_93I0kpFvhp77Meta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-pages",
    path: "pages",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-posts-slug",
    path: "posts/:slug()",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/posts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-posts-category",
    path: "posts/category",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/posts/category.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-posts",
    path: "posts",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-posts-new",
    path: "posts/new",
    component: () => createClientPage(() => import("/home/galaxyturbo/public_html/pages/dashboard/posts/new.client.vue").then(m => m.default || m))
  },
  {
    name: "dashboard-settings",
    path: "settings",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "users",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-uservideos-id",
    path: "uservideos/:id()",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/uservideos/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-uservideos",
    path: "uservideos",
    component: () => import("/home/galaxyturbo/public_html/pages/dashboard/uservideos/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/galaxyturbo/public_html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landin",
    path: "/landin",
    meta: landina3WRaIq7TqMeta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/landin.vue").then(m => m.default || m)
  },
  {
    name: "notadminpanel",
    path: "/notadminpanel",
    component: () => import("/home/galaxyturbo/public_html/pages/notadminpanel/index.vue").then(m => m.default || m)
  },
  {
    name: "pdr-planishing",
    path: "/pdr-planishing",
    meta: pdr_45planishingQ5yvyk0JO4Meta || {},
    component: () => import("/home/galaxyturbo/public_html/pages/pdr-planishing.vue").then(m => m.default || m)
  }
]