
import * as ipxRuntime$1rcqYBAlgv from '/home/galaxyturbo/public_html/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "192.168.1.110",
    "192.168.1.110:9000",
    "localhost",
    "galaxyturbo.co",
    "storage.myvision.dev",
    "gvcrm.liara.run"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$1rcqYBAlgv, defaults: {} }
}
        