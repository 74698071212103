import Swal from "sweetalert2";

export const useToast = (icon: any,title: any) => {
    const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});
return Toast.fire({
  icon: icon,
  title: title
});
}
